<template>
    <div>
        <v-overlay :value="overlay" :z-index="100">
            <v-progress-circular indeterminate size="64">
                ...
            </v-progress-circular>
        </v-overlay>
        <HeaderFive>
            <img slot="logo" src="../assets/img/xgm/icon_text.png" />
        </HeaderFive>

        <!-- Start Bradcaump area -->
        <div>
            <v-card>
                <vue-advanced-chat height="calc(100vh - 20px)" :current-user-id="currentUserId"
                    :rooms="JSON.stringify(rooms)" :rooms-loaded="true" :messages="JSON.stringify(messages)"
                    :messages-loaded="messagesLoaded" @send-message="sendMessage($event.detail[0])"
                    @fetch-messages="fetchMessages($event.detail[0])" @add-room="fetchRoom()"
                    @open-file="openFile($event.detail[0])" @send-message-reaction="emo($event.detail[0])"
                    :multiple-files="multipleFiles" :accepted-files="type">
                    <div slot="add-icon"></div>
                    <div slot="microphone-icon"></div>
                    <div v-for="message in messages" :key="message._id" :slot="'dropdown-icon_' + message._id">
                    </div>
                    <div slot="dropdown-icon"></div>
                    <div slot="spinner-icon-message-file"></div>
                </vue-advanced-chat>
            </v-card>
        </div>
        <Footer />
    </div>

</template>

<script>
import HeaderFive from "../components/header/HeaderFive";
import Footer from "../components/footer/FooterTwo";
import { register } from 'vue-advanced-chat';
import api from "../api/api";
register();
export default {
    components: {
        HeaderFive,
        Footer,
    },
    data() {
        return {
            currentUserId: '0002',
            rooms: [
                {
                    roomId: '1',
                    roomName: '智能客服',
                    avatar: require('../assets/img/xgm/dog.png'),
                    users: [
                        { _id: '0001', username: '智能客服' },
                        { _id: '0002', username: '用户' },
                        { _id: '0003', username: '系统' }
                    ]
                }
            ],
            messages: [],
            messagesLoaded: false,
            type: 'image/png, image/jpeg',
            multipleFiles: false,
            overlay: false
        }
    },
    mounted() {
    },
    methods: {
        emo(input) {
            console.log(input);
            const messageId = input.messageId
            for (const one of this.messages) {
                if (one._id == messageId) {
                    one.content = one.content + input.reaction.unicode;
                }
            }
        },
        messageAction({ roomId, action, messages }) {
            console.log(111)
            console.log(roomId)
            console.log(action)
            console.log(messages)
        },
        fetchRoom() {
            console.log('无房间')
            const room = {
                roomId: '2',
                roomName: '智能客服',
                avatar: require('../assets/img/xgm/room.webp'),
                users: [
                    {
                        _id: '0001', username: '智能客服',
                    },
                    { _id: '0002', username: '用户' }
                ]
            }
            this.rooms.push(room)
        },
        openFile(message, file) {
            console.log(message);
            console.log(file);
        },
        fetchMessages({ options = {} }) {
            console.log(options)
            this.messages = [
                ...this.messages,
                {
                    _id: this.messages.length,
                    content: '你好，有什么能够帮助你的吗？',
                    senderId: '0001',
                    timestamp: new Date().toString().substring(16, 21),
                    date: new Date().toDateString()
                }
            ]
            this.messagesLoaded = true
        },
        answer(res) {
            this.messages = [
                ...this.messages,
                {
                    _id: this.messages.length,
                    content: res,
                    senderId: '0001',
                    timestamp: new Date().toString().substring(16, 21),
                    date: new Date().toDateString()
                }
            ]
            this.messagesLoaded = true;
        },

        addMessages(reset) {
            const messages = []
            for (let i = 0; i < 30; i++) {
                messages.push({
                    _id: reset ? i : this.messages.length + i,
                    content: `${reset ? '' : 'paginated'} message ${i + 1}`,
                    senderId: '4321',
                    username: 'John Doe',
                    date: '13 November',
                    timestamp: '10:20'
                })
            }
            return messages;
        },
        // 发送消息
        async sendMessage(message) {
            console.log('当前消息:');
            console.log(message);
            this.overlay = true;
            const files = message.files;
            const messageFiles = [];
            let imageBase64 = '';
            // let localUrl = '';
            if (files != null && files.length > 0) {
                for (const file of files) {
                    // imageBase64 = await this.blobToBase64(file.blob);
                    imageBase64 = await this.compressImageToBase64(file.blob);
                    // imageBase64 = await this.resizeBlobImage(file.blob, 500, 500);
                    // localUrl = file.localUrl;
                    const img = {
                        name: file.name,
                        size: file.size,
                        type: file.extension,
                        url: file.localUrl,
                        preview: imageBase64,
                        extension: file.extension,
                        show: false
                    };
                    messageFiles.push(img);
                }

            }
            // let img_md = `<img src='` + localUrl + `'/>`;
            // console.log(img_md)
            this.messages = [
                ...this.messages,
                {
                    _id: this.messages.length,
                    content: message.content,
                    files: messageFiles,
                    senderId: this.currentUserId,
                    // senderId: '0003',
                    timestamp: new Date().toString().substring(16, 21),
                    date: new Date().toDateString(),
                    isImageLoading: false,
                    loading: false
                }
            ]
            console.log(this.messages);
            // 请求api
            const checkTag = this.checkAccount();
            if (checkTag == false) {
                return;
            }
            this.messagesLoaded = false
            const param = {
                'message': message.content,
                'image': imageBase64
            }
            // this.hiddleCircle()
            api.post('/center/chat', param).then(response => {
                console.log(response);
                const data = response.data;
                if (data.code == 200 || data.code == 6001) {
                    this.answer(data.data);
                } else {
                    this.answer("暂无响应");
                }
                this.messagesLoaded = true;
                this.overlay = false;
            }).catch(error => {
                console.log(error);
                this.answer("暂无响应")
                this.messagesLoaded = true;
                this.overlay = false;
            });
            // this.overlay = false;
        },

        addNewMessage() {
            setTimeout(() => {
                this.messages = [
                    ...this.messages,
                    {
                        _id: this.messages.length,
                        content: 'NEW MESSAGE',
                        senderId: '1234',
                        timestamp: new Date().toString().substring(16, 21),
                        date: new Date().toDateString()
                    }
                ]
            }, 2000)
        },
        checkAccount() {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user == null) {
                this.showTip();
                return false;
            }
            if (user != null) {
                const token = user.token;
                api.get('/center/check?token=' + token).then(response => {
                    const data = response.data;
                    if (data.code != 200) {
                        this.showTip();
                        return false;
                    }
                });
            }
            return true;
        },
        showTip() {
            this.messages = [
                ...this.messages,
                {
                    _id: this.messages.length,
                    content: '您尚未登陆...将跳转用户中心...若自动跳转失败点击[用户中心](/user)',
                    senderId: '0001',
                    timestamp: new Date().toString().substring(16, 21),
                    date: new Date().toDateString()
                }
            ];
            this.overlay = false;
            setTimeout(() => {
                window.open('/user');
            }, 3000);

        },
        blobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
                reader.readAsDataURL(blob);
            });
        },
        hiddleCircle() {
            // 使用querySelectorAll选择所有ID为"vac-circle"的元素
            var vacCircles = document.querySelectorAll('[id="vac-circle"]');
            console.log('element');
            console.log(vacCircles);
            // 遍历所有找到的元素，并隐藏它们
            vacCircles.forEach(function (element) {
                element.style.display = 'none';
            });
        },
        async compressImageToBase64(blob, maxSizeKB = 800) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = async (event) => {
                    try {
                        const img = new Image();
                        img.src = event.target.result;
                        img.onload = async () => {
                            let quality = 0.5; // 初始质量设为1.0  
                            let canvas = document.createElement('canvas');
                            let ctx = canvas.getContext('2d');
                            // 初始尺寸与原图相同，你可以根据需要调整  
                            canvas.width = img.width;
                            canvas.height = img.height;
                            // 尝试降低质量直到图片大小小于目标值  
                            while (quality > 0.1) {
                                ctx.clearRect(0, 0, canvas.width, canvas.height);
                                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                                // 将canvas内容转换为DataURL  
                                let dataUrl = canvas.toDataURL('image/jpeg', quality);
                                // 转换为Blob对象以获取准确大小  
                                // 注意：这里使用了fetch的Blob响应来模拟Blob对象，但实际应用中你已经有Blob对象了  
                                // 这里只是为了演示如何从DataURL获取Blob大小  
                                let blobData = await (await fetch(dataUrl)).blob();
                                let sizeKB = blobData.size / 1024; // 计算Blob大小（KB）  
                                console.log('sizeKB:' + sizeKB);
                                if (sizeKB <= maxSizeKB) {
                                    // 如果大小满足要求，则直接返回DataURL（已经是Base64编码）  
                                    resolve(dataUrl);
                                    return;
                                }
                                // 如果不满足要求，则降低质量并继续尝试  
                                quality -= 0.1;
                            }
                            // 如果质量降低到0.1仍然不满足要求，你可能需要处理这种情况  
                            // 例如，返回原始图片的Base64编码或抛出一个错误  
                            resolve(canvas.toDataURL('image/jpeg', 0.1)); // 最低质量返回  
                        };
                    } catch (error) {
                        reject(error);
                    }
                };
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.inpur-error,
.inpur-success {
    display: block;
    margin-top: 5px;
    font-size: 14px;
}

.inpur-error {
    color: #f10;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 800px;
    width: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }
}

.mapouter {
    position: relative;
    width: 100%;
    height: 100%;
}

.bg_image--12 {
    background: url("../assets/img/xgm/user_banner.png");
    background-size: cover;
}

.vac-loader-wrapper #vac-circle {
    visibility: hidden !important;
}
</style>